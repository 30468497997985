import "./App.css";
import ConstructionPage from "./ConstructionPage";

function App() {
  return (
    <div className="App">
      <ConstructionPage />
    </div>
  );
}

export default App;
